/* eslint-disable */
// prettier-ignore
import * as Types from '../../../../../../graphql/__generated__/index';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OfferingAttributesFragment = {
  __typename?: 'OfferingAttributes';
  leftLeadId?: string | null;
  leftLeadName?: string | null;
};

export type GetLeftleadQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OfferingAttributesFilterInput>;
}>;

export type GetLeftleadQuery = {
  __typename?: 'Query';
  offeringAttributes?: {
    __typename?: 'OfferingAttributesCollectionSegment';
    totalCount: number;
    items?: Array<{
      __typename?: 'OfferingAttributes';
      leftLeadId?: string | null;
      leftLeadName?: string | null;
    }> | null;
  } | null;
};

export const OfferingAttributesFragmentDoc = gql`
  fragment OfferingAttributes on OfferingAttributes {
    leftLeadId
    leftLeadName
  }
`;
export const GetLeftleadDocument = gql`
  query GetLeftlead($filter: OfferingAttributesFilterInput) {
    offeringAttributes(where: $filter) {
      items {
        ...OfferingAttributes
      }
      totalCount
    }
  }
  ${OfferingAttributesFragmentDoc}
`;

/**
 * __useGetLeftleadQuery__
 *
 * To run a query within a React component, call `useGetLeftleadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeftleadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeftleadQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetLeftleadQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLeftleadQuery, GetLeftleadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLeftleadQuery, GetLeftleadQueryVariables>(GetLeftleadDocument, options);
}
export function useGetLeftleadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeftleadQuery, GetLeftleadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLeftleadQuery, GetLeftleadQueryVariables>(
    GetLeftleadDocument,
    options
  );
}
export function useGetLeftleadSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLeftleadQuery, GetLeftleadQueryVariables>
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLeftleadQuery, GetLeftleadQueryVariables>(
    GetLeftleadDocument,
    options
  );
}
export type GetLeftleadQueryHookResult = ReturnType<typeof useGetLeftleadQuery>;
export type GetLeftleadLazyQueryHookResult = ReturnType<typeof useGetLeftleadLazyQuery>;
export type GetLeftleadSuspenseQueryHookResult = ReturnType<typeof useGetLeftleadSuspenseQuery>;
export type GetLeftleadQueryResult = Apollo.QueryResult<
  GetLeftleadQuery,
  GetLeftleadQueryVariables
>;
